window.addEventListener('scroll', function(event) {

    var scroll = $(window).scrollTop();
    var windowHeight = $(window).outerHeight();
    var viewPortBreak = scroll+windowHeight;
    var documentHeight = $(document).outerHeight();
    var documentBottomTop = (documentHeight-windowHeight);

    // $('.js-section').each(function(){
    //
    //     var top = Math.floor($(this).offset().top);
    //     var bottom = Math.floor((top+$(this).outerHeight(true)));
    //
    //     if ( scroll >= top && !( bottom <= scroll ) ) {
    //         $(this).addClass('is-active').siblings('.js-section.is-active').removeClass('is-active');
    //         var id = $(this).attr('id');
    //         $('.Header-menuItem a[href="#'+id+'"]').parent('.Header-menuItem').addClass('is-active').siblings('.Header-menuItem').removeClass('is-active');
    //         return false;
    //     }
    //
    // });

    if ( scroll < $('.Header').outerHeight(true) ) {
        $('body').addClass('is-scrolledTop');
    } else {
        $('body').removeClass('is-scrolledTop');
    }

}, { passive: true });
var event = new Event('scroll');
window.dispatchEvent(event);
