$('.js-filter').on('click',function(){
    var filter = $(this).attr('data-filter');
    if ( filter ) {
        if ( !$(this).hasClass('is-active') ) {
            $('.js-stack').find('.js-item').each(function(){
                var term = $(this).attr('data-term');
                if (term.indexOf(filter) >= 0) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
            $(this).addClass('is-active').siblings('.js-filter').removeClass('is-active');
            $('.js-stack').addClass('is-filtered');
        } else {
            $(this).removeClass('is-active');
            $('.js-stack').find('.js-item').show();
            $('.js-stack').removeClass('is-filtered');
        }
    }
});
