function is_touch_device() {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
}

$(window).on('load',function(){
    $('body').addClass('is-loaded');
});

$(window).on('beforeunload',function(e){
    $('body').removeClass('is-loaded');
});

$('.js-toggleMenu').on('click',function(){
    $('body').toggleClass('is-menu');
});

$('.js-toggleArchive').on('click',function(){
    $('.js-archive').toggleClass('is-collapsed');
});

function harold(standIn) {
    if (standIn < 10) {
        standIn = '0' + standIn
    }
    return standIn;
}

if ( $('.js-clock').length ) {

    function clock() {
        var time = new Date(),
        hours = time.getHours(),
        minutes = time.getMinutes(),
        seconds = time.getSeconds();
        $('.js-clock').html(harold(hours) + ":" + harold(minutes) + ":" + harold(seconds));
    }

    setInterval(clock, 1000);

}

if ( $('.js-countdown').length ) {

    var countDownDate = new Date("December 15, 2017 15:00:00").getTime();
    var x = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        hours = (hours + (days*24));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        $('.js-countdown').html(harold(hours) + ":" + harold(minutes) + ":" + harold(seconds));

        if (distance < 0) {
            clearInterval(x);
        }
    }, 1000);

}

$(document).ready(function(){
    if ( $('.js-tablesorter').length ) {
        $('.js-tablesorter').tablesorter({
            sortList: [[5,1]]
        });
    }
});

if ( $('.js-journalPost').length && !is_touch_device() ) {

    var journalPostTimer = false,
        journalPostTimeout;

    $('.js-journalPost').on('mouseenter',function(){
        journalPostTimer = true;
        var activePost = $(this);
        if ( activePost.hasClass('Journal-entry--overflowing') ) {
            journalPostTimeout = setTimeout(function(){
                activePost.addClass('is-open');
                activePost.siblings('.js-journalPost').addClass('is-faded');
                journalPostTimer = false;
            }, 1200);
        }
    });

    $('.js-journalPost').on('mouseleave',function(){
        if ( journalPostTimer ) {
            clearTimeout(journalPostTimeout);
            journalPostTimer = false;
        } else if ( $('.js-journalPost.is-faded').length ) {
            $('.js-journalPost.is-faded').removeClass('is-faded');
            $('.js-journalPost.is-open').removeClass('is-open');
        }
    });

}
