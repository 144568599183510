function zoomImage(el) {
    var img = el.clone();
    $('body').prepend('<div class="Zoom js-zoom"></div>');
    $('.js-zoom').append(img);
    $('.js-zoom').addClass('is-visible');
    $('.js-zoom').on('click',function(){
        $(this).remove();
    });
}

$('.js-image').on('click',function(){
    var img = $(this);
    zoomImage(img);
});
